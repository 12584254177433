.App {
  width: 100%;
  height: 100%;
  background-color: #282c34;
}

.Content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-wrap: wrap;
}

.Helmet {
  max-width: 400px;
}

.TitleWrapper {
  color: var(--nextui-primary);
}

.ComingSoon {
  font-size: 72px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

html {
  background-color: #282c34;
}

.test {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
