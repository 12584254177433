.Beast {
  border: 2px solid #ffdf00;
  color: #ffdf00;
}

.Dragon {
  border: 2px solid #bb0000;
  color: #bb0000;
}

.Rune {
  border: 2px solid #00bce4;
  color: #00bce4;
}

.Adamant {
  border: 2px solid #007546;
  color: #007546;
}

.Mithril {
  border: 2px solid #4d26cc;
  color: #4d26cc;
}

.Steel {
  border: 2px solid #dfdfdf;
  color: #dfdfdf;
}

.Iron {
  border: 2px solid #5f5f5f;
  color: #5f5f5f;
}

.Bronze {
  border: 2px solid #774600;
  color: #774600;
}
